/* 
  ### Customized Chart Components ### 
  Predefined component's behaviors for the Dashboard
*/

// 01_Summaries -----------------------------------------------------------------
  // Cubes
  import J2_Cube_A                  from '../01_Summaries/Cubes/Cube_A/index.vue'
  import J2_Cube_B                  from '../01_Summaries/Cubes/Cube_B/index.vue'
  import J2_Cube_C                  from '../01_Summaries/Cubes/Cube_C/index.vue'
  import J2_Cube_BIND               from '../01_Summaries/Cubes/Cube_BIND/index.vue'
  import J2_Sentence_A              from '../01_Summaries/Cubes/Sentence_A/index.vue'
  import J2_Week_Performance        from '../01_Summaries/Cubes/Weekly/index.vue'
  import J2_Week_Performance_UNIT   from '../01_Summaries/Cubes/Weekly_UNIT/index.vue'
  // Gauges
  import J2_Gauge_Normal            from '../01_Summaries/Gauges/Normal/index.vue'
  // Tubes
  import J2_Tube_A                  from '../01_Summaries/Tubes/Normal/index.vue'
  import J2_Tube_UNIT               from '../01_Summaries/Tubes/Unit/index.vue'

// 02 DataTables -------------------------------------------------------------------
  // Chart
  import J2_Progress                from '../02_DataTables/Chart/Progress/index.vue'
  import J2_Punch                   from '../02_DataTables/Chart/Punch/index.vue'
  // Group
  import J3_TBL_GROUP_NORMAL        from '../02_DataTables/Group/Normal/index.vue'
  // Talble
  import J2_DataTable_A             from '../02_DataTables/Table/ver_A/index.vue'
  import J2_DataTable_B             from '../02_DataTables/Table/ver_B/index.vue'
  import J2_DataTable_C             from '../02_DataTables/Table/ver_C/index.vue'

// 03 PRIMAVERA -------------------------------------------------------------------------
  // DataTable
  import J2_P6_TABLE_EL             from '../03_Primavera/DataTable/Table_EL/index.vue'
  // Histogram
  import J2_P6_SCURVE_MINI          from '../03_Primavera/Histogram/SCurve_Mini/index.vue'
  import J2_P6_TREND_EL             from '../03_Primavera/Histogram/Trend_EL/index.vue'
  // Summary
  import J2_P6_DONUTS               from '../03_Primavera/Summary/Donuts/index.vue'
  import J2_P6_DONUTS_R1            from '../03_Primavera/Summary/Donuts_R1/index.vue'
  import J2_P6_TUBE_EL              from '../03_Primavera/Summary/Tube_EL/index.vue'
  

// 04 Charts -------------------------------------------------------------------------
  import J3_CHT_HIS_AXES_NORMAL     from '../04_Charts/Histogram/Axes_Normal/index.vue'
  import J3_CHT_HIS_AXES_STACK      from '../04_Charts/Histogram/Axes_Stack/index.vue'

  import J3_CHT_BAR_VER_MULTI       from '../04_Charts/BarChart/Ver_Multi/index.vue'
  import J3_CHT_BAR_VER_STACK       from '../04_Charts/BarChart/Ver_Stack/index.vue'
  import J3_CHT_BAR_HOZ_MULTI       from '../04_Charts/BarChart/Hoz_Multi/index.vue'

  import J3_CHT_SFT_INJURY          from '../04_Charts/Safety/Injury/index.vue'

// 05 Specialized Chart --------------------------------------------------------------
  import J2_Skyline_Month_A         from '../05_Specialized/Skyline/Month_A/index.vue'

  // Viewer
  import J2_Filter_Viewer           from '../09_Components/Graphics/Filter_Viewer/index.vue'
  import J2_SVG_Viewer              from '../09_Components/Graphics/SVG_Viewer/index.vue'
  import J2_SVG_Viewer_v2           from '../09_Components/Graphics/SVG_Viewer_v2/index.vue'

  // Summary table depth2 for Crisp
  import J2_Summary_Table           from '../09_Components/Developing/Summary_Table_Dep2/index.vue'


export default {
  // 01_Summaries
    J2_Cube_A,
    J2_Cube_B,
    J2_Cube_C,
    J2_Cube_BIND,
    J2_Sentence_A,
    J2_Week_Performance,
    J2_Week_Performance_UNIT,
    J2_Gauge_Normal,
    J2_Tube_A,
    J2_Tube_UNIT,
    
  // 02 DataTables
    J2_Progress,
    J2_Punch,
    J3_TBL_GROUP_NORMAL,
    J2_DataTable_A,
    J2_DataTable_B,
    J2_DataTable_C,

  // 03 PRIMAVERA
    J2_P6_TABLE_EL,
    J2_P6_SCURVE_MINI,
    J2_P6_TREND_EL,
    J2_P6_DONUTS,
    J2_P6_DONUTS_R1,
    J2_P6_TUBE_EL,
    
  // 04 Common Chart
    J3_CHT_HIS_AXES_NORMAL,
    J3_CHT_HIS_AXES_STACK,
    J3_CHT_BAR_VER_MULTI,
    J3_CHT_BAR_VER_STACK,
    J3_CHT_BAR_HOZ_MULTI,
    J3_CHT_SFT_INJURY,

  //05 Specialized Chart
    J2_Skyline_Month_A,

  // Viewer
    J2_Filter_Viewer,
    J2_SVG_Viewer,
    J2_SVG_Viewer_v2,

  // Summary table depth2 for Crisp
    J2_Summary_Table,

}
