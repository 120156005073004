import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller(d) {

      let actData = this.chartData.filter(f=> f.CDATE <= this.cutoff)


      this.draw_BarChart()

      // Line Chart
      if(this.secondary.visible == 'Y') {
        this.chart.styles.filter(f=> f.type == 'LINE').forEach((st,i)=> {
          let data = (st.act == 'Y') ? actData : this.chartData
          this.draw_LineChart(data, st)
          if(this.chart.lastValue > 0) this.draw_lastValue(data, st) // -----> below
        })
      }
    },


    draw_BarChart() {
      

      this.chartData.forEach((d,i)=> {
        
        let stackY = this.chart.height
        let sum = 0
        this.chart.styles.filter(f=> f.type == 'BAR').forEach((st,s)=> {

          if(st.act == 'Y' && d.CDATE > this.cutoff) return

          let value = (d[st.code] < 0) ? 0 : d[st.code]
          stackY = stackY - this.primary.axis(value)

          let bar = this.canvasChart
          .append('g')
          .attr('id', `BAR_${i}_${s}_${this.localId}`)
          .attr('opacity', 1)
          .call(this.call_LINK, st.filter, d, st)

          bar
          .append('rect')
          .attr('x', d.mx - (st.size/2))
          .attr('y', stackY)
          .attr('width', st.size).attr('height', this.primary.axis(value))
          .attr('fill', st.color).attr('opacity', st.opacity)

          if(st.vSize > 0 && value > 0) {
            bar
            .append('text')
            .attr('x', d.mx)
            .attr('y', stackY + (this.primary.axis(value)/2) +1.5)
            .attr('font-size', st.vSize).attr('fill',  st.vColor)
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(value)
          }
          sum = sum + value
        })

        // Period Total
        let stack = this.chart.stackTotal
        if(stack.visible == 'Y') {
          this.canvasChart
          .append('text')
          .attr('x', d.mx)
          .attr('y', stackY - 2)
          .attr('font-size', stack.tSize).attr('fill',  stack.tColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'baseline ')
          .text(sum)
        }

      })
    },


    draw_LineChart(_data, _st) {
      let lineFunc = d3.line()
      .x(d => d.mx)
      .y(d => this.secondary.axis(d[_st.code]))

      this.canvasChart
      .append('path')
      .attr('d', lineFunc(_data))
      .attr('stroke', _st.color).attr('stroke-width', _st.size)
      .style("stroke-dasharray", (_st.ltype, _st.ltype))
      .attr('fill', 'none').attr('opacity', _st.opacity)

      _data.forEach((d,i)=> {
        // Node
        if(_st.node > 0) {
          this.canvasChart
          .append('circle')
          .attr('cx', d.mx)
          .attr('cy', this.secondary.axis(d[_st.code]))
          .attr('r', _st.node)
          .attr('fill', _st.color).attr('opacity', _st.opacity)
        }
        // Value
        if(_st.vSize > 0 && d[_st.code] > 0) {
          this.canvasChart
          .append('text')
          .attr('x', d.mx)
          .attr('y', this.secondary.axis(d[_st.code]) -4)
          .attr('font-size', _st.vSize).attr('fill',  _st.vColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'bottom')
          .text(d[_st.code])
        }
      })
    },


    draw_lastValue(_data, _st) {
      // draw the final value
      let lastOne = null
      if(this.timeline.type == 'week' ) lastOne = _data.find(f=> f.CDATE == this.cutoff)
      if(this.timeline.type == 'month' ) lastOne = _data.find(f=> f.CDATE.substr(0,7) == this.cutoff.substr(0,7))

      if(_st.type == 'LINE') {
        if(!lastOne) return
        this.canvasChart
        .append('text')
        .attr('x', lastOne.mx + 5)
        .attr('y', this.secondary.axis(lastOne[_st.code])+1)
        .attr('font-size', this.chart.lastValue).attr('fill',  _st.vColor)
        .attr('alignment-baseline', 'middle')
        .text(lastOne[_st.code])
      }
    },

  }
}