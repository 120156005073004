import gmx_zMixins          from '../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData        from '../primitives/localData'

import lmx_LocalValues      from './set_LocalValues'
import lmx_Watching         from './Watching'

import lmx_Events           from './Events'

import lmx_draw_Forms       from './draw_Forms'



export default {
  mixins: [
    gmx_zMixins, 
    lmx_localData,

    // Local Mixins
    lmx_Watching,
    lmx_Events,
    
    lmx_LocalValues,
    
    lmx_draw_Forms,

  ],
}
