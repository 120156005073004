import * as d3 from 'd3'

export default {

  methods: {

    set_LocalValues(){

      this.chartData = JSON.parse(JSON.stringify(this.DataItems))
      this.cutoff = JSON.parse(JSON.stringify(this.Queries.SQL1[0].CUTOFF))
      
      let json = JSON.parse(JSON.stringify(this.JQueries))

      //##### JSON #####
      this.title       = json.title
      this.timeline    = json.timeline
      this.primary     = json.primary
      this.secondary  = json.secondary
      this.chart       = json.chart
      this.legends     = json.legends
      this.summary     = json.summary
      this.filters     = json.filters

      // Calculate the Height of Canvas
      this.Canvas.CanvasWidth = this.chart.width + this.chart.x * 2
      this.Canvas.CanvasHeight = this.chart.height + this.chart.y + 40


      // Setting Timeline ----------------------------
      this.timeline.axis = d3.scaleBand()
      .domain(this.chartData.map(d => d.CDATE ))
      .range([0, this.chart.width])
      this.timeline.band = this.timeline.axis.bandwidth()

      if(this.timeline.type == 'week') this.gmx_BandScaleValues_Week(this.chartData) // ##### init_TimelineValue.js #####
      if(this.timeline.type == 'month') this.gmx_BandScaleValues_Month(this.chartData) // ##### init_TimelineValue.js #####

      //
      //
      //
      //
      //


      // Setting Primary --------------------------------------------------------
      this.chartData.forEach(d=> {
        let sum_ = 0
        this.chart.styles.filter(f=> f.type == 'BAR').forEach(s=> {
          sum_ = sum_ + d[s.code]
        })
        d.sum = sum_
      })
      this.primary.max = this.primary.max = this.getMaxBound_v2(d3.max(this.chartData, c => c.sum))

      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      if(this.primary.position == 'left') this.primary.titleX = -this.primary.padding
      if(this.primary.position == 'right') this.primary.titleX = this.chart.width + this.primary.padding



      // Setting secondary ------------------------------------------------------
      if(this.secondary.visible == 'Y') {

        let val = null
        let unit = this.secondary.unit.substr(this.secondary.unit.length-1, 1)
        if(unit == '%') {
          val = this.secondary.unit.substr(0,this.secondary.unit.length-1)
          this.secondary.unit = '%'
        }
        
        if(unit == '%' && val) {
          this.secondary.max = val
        } else {
          let maxArray = []
          this.chart.styles.filter(f=> f.type == 'LINE').forEach((s,i)=> {
            maxArray.push(d3.max(this.chartData, c => c[s.code]))
          })
    
          this.secondary.max = d3.max(maxArray)
          if(this.secondary.max == 0) this.secondary.max = 25; else this.secondary.max = this.getMaxBound_v2(this.secondary.max)
        }
      
        this.secondary.axis = d3.scaleLinear()
        .domain([0, this.secondary.max])
        .range([this.chart.height, 0])

        if(this.secondary.position == 'left') this.secondary.titleX = -this.secondary.padding
        if(this.secondary.position == 'right') this.secondary.titleX = this.chart.width + this.secondary.padding
      }
    },


  }
}