
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({

    rawData:        null,
    chartData:      null,

    canvasForms:    null,
    canvasDropdown: null,
    canvasChart:    null,

    chart: {
      x: 75, 
      y: 70,
      width: 980,
      height: 300,

      line: [
        {code: 'P_CUM', type: 'PLAN',sColor: '#757575', sWidth: .5},
        {code: 'A_CUM', type: 'ACT', sColor: '#44A9DF', sWidth: 1.5},
      ],

      colors: ['#264653', '#287271', '#2a9d8f', '#8ab17d', '#babb74', '#e9c46a', '#efb366', '#f4a261', '#ee8959', '#e76f51']
    },

    timeline:     {
      month: [],
      year: [],
      scale: null,
      month_count: 0,
      monthWidth: 0,
    },

    icon:         icon.arrow,
    scaleX: null,
    primary:      {},
    secoundary:   {},

    dropdown: [
      {idx: 0, id: 'PHASE',  x: 0,   y: 0, boxWidth: 115,},
      {idx: 1, id: 'GROUP',  x: 130, y: 0, boxWidth: 110,},
      {idx: 2, id: 'SUBCONT', x: 250, y: 0, boxWidth: 120,},
    ],

    combine: [],
    current:{ phase: false, group: false, subcont: false },

    legends: [
      {title: 'Plan',   code: 'P_WK',  width: 35},
      {title: 'Actual', code: 'A_WK',  width: 35},
      {title: 'Delta',  code: 'D_WK',  width: 35},
    ],











  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}