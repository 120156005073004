
import * as d3 from 'd3'

export default {

  methods: {

    draw_Table() {

      let x = 0
      this.columns.forEach(d=> {
        d.sx = x
        d.mx = x + d.width/2
        x = x + d.width
        d.ex = x
      })
      this.tblLen = x

      let block = this.canvasForms
      .append('g')
      .attr('id', `BLOCK_STATUS`)
      .attr('transform', `translate(30.5, 0.5)`)
      .attr('font-family', 'roboto')

      block
      .append('line')
      .attr('x1', 0).attr('y1', -12).attr('x2', this.tblLen).attr('y2', -12)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.columns.forEach((d,i) => {
        block
        .append('text')
        .attr('x', d.mx)
        .attr('y', (i < 2) ? 12 : 24)
        .attr('font-size', (i < 2) ? 11 : 10)
        .attr('fill', (i < 2) ? '#000' : '#333')
        .attr('text-anchor', 'middle')
        .text(d.name)
      })

      block
      .append('line')
      .attr('x1', this.columns[1].ex).attr('y1', 12).attr('x2', this.tblLen).attr('y2', 12)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      block
      .append('line')
      .attr('x1', 0).attr('y1', 28).attr('x2', this.tblLen).attr('y2', 28)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      // Vertical Line
      this.columns.forEach((d,i)=> {
        if(i < 2) return
        block
        .append('line')
        .attr('x1', d.sx).attr('y1', (d.name == 'Total' || i == 1) ? -11 : 11)
        .attr('x2', d.sx).attr('y2', (this.schList.length*20) + 42 - 13)
        .attr('stroke', (d.name == 'Total') ? '#bcbcbc' : '#E0E0DF')
        .attr('stroke-width', .5)

        block
        .append('text')
        .attr('x', d.mx).attr('y', 2)
        .attr('font-size', 11)
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .text(d.head)
      })

      // DataGrid
      let y = 42
      this.schList.forEach((d,i)=> {
        if(i<this.schList.length-3 && d.ZONE != 'OVERALL') {
          block
        .append('line')
        .attr('x1', this.columns[0].ex).attr('y1', y-13)
        .attr('x2', this.columns[0].ex).attr('y2', y+7)
        .attr('stroke', (d.name == 'Total') ? '#bcbcbc' : '#E0E0DF')
        .attr('stroke-width', .5)
        }
        
        this.columns.forEach((col, c)=> {

          if(c<2) {
            block
            .append('text')
            .attr('x', (i>this.schList.length-3) ? col.ex : col.sx + 4)
            .attr('y', y)
            .attr('fill', '#000')
            .attr('font-size', 10)
            .attr('text-anchor', (i>this.schList.length-3) ? 'middle' : 'start')
            .text(()=> {
              if(i>0 && c==0 && d.AREA == this.schList[i-1].AREA) {
                return ''
              } else {
                if(c==1 && i>this.schList.length-3) return ''
                if(c==1 && d[`${col.code}`] == 'OVERALL' && d[`${this.columns[0].code}`] != 'LQ') return 'SUB-TOTAL'
                if(c==0 && d[`${col.code}`] == 'OVERALL') return 'GRAND-TOTAL'
                return d[`${col.code}`]
              }
            })
          } else {
            
            block
            .append('text')
            .attr('x', col.mx)
            .attr('y', y)
            .attr('fill', ()=> {
              if(col.name == 'Total')  return '#333'
              if(col.name == 'Compl.') return '#4CAE4E'
              if(col.name == 'Ahead')  return '#44A9DF'
              if(col.name == 'Behind') return '#F35E90'
            })
            .attr('font-size', 10)
            .attr('text-anchor', 'middle')
            .text((d[`${col.code}`] != null) ? Math.abs(d[`${col.code}`]) : '')
            .call(
              this.call_LINK, 
              {
                AREA: d.AREA,
                ZONE: d.ZONE,
                STAGE: col.code.split('_')[0],
                STATUS: col.code.split('_')[1],
              }
            )
          } 
        })

        if(i>0 && d.AREA != this.schList[i-1].AREA) {
          block
          .append('line')
          .attr('x1', 0)
          .attr('y1', y-13)
          .attr('x2', this.tblLen).attr('y2', y-13)
          .attr('stroke', (i == this.schList.length-1) ? '#757575' : '#bcbcbc')
          .attr('stroke-width', .5)
        } else if(i>0) {
          block
          .append('line')
          .attr('x1', this.columns[1].sx)
          .attr('y1', y-13)
          .attr('x2', this.tblLen).attr('y2', y-13)
          .attr('stroke', '#E0E0DF')
          .attr('stroke-width', .5)
        }
        
        y = y + 20
      })

      block
      .append('line')
      .attr('x1', 0).attr('y1', y-13).attr('x2', this.tblLen).attr('y2', y-13)
      .attr('stroke', '#757575').attr('stroke-width', .5)
    },

    
  }
}