import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      let sChart = this.canvasChart
      .append('g')
      .attr('id', 'DROPDOWN_CHARTS')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      // Retrieve the selected dropdown item"
      let phase = this.current.phase, group = this.current.group, subcont = this.current.subcont
      let status = `${Boolean(phase)} - ${Boolean(group)} - ${Boolean(subcont)}`


      // Determine axes data and line chart data ----------------------------------------------------------------------------
      let axisData = null

      switch(status) {
        case 'false - false - false':  
          axisData = this.chartData.filter(f=> f.PHASE == 'OVERALL')
          break
      
        case 'true - false - false':  
          axisData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP == 'OVERALL')
          break

        case 'true - true - false':  
          axisData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP == group && f.SUBCONT == 'OVERALL')
          break

        case 'true - true - true':  
          axisData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP == group && f.SUBCONT == subcont)
          break
      }
      this.set_Axes(axisData) // -----> draw_Chart.js
      this.draw_Grid(sChart) // -----> draw_Chart.js


      // Determine the group of the bar chart data ----------------------------------------------------------------------------
      let chtData = null, groupArray = null, filterItem = null

      switch(status) {
        case 'false - false - false':  
          chtData = this.chartData.filter(f=> f.PHASE != 'OVERALL' && f.GROUP == 'OVERALL')
          filterItem = 'PHASE'
          break
      
        case 'true - false - false':  
          chtData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP != 'OVERALL' && f.SUBCONT == 'OVERALL')
          filterItem = 'GROUP'
          break

        case 'true - true - false':  
          chtData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP == group && f.SUBCONT != 'OVERALL')
          filterItem = 'SUBCONT'
          break

        case 'true - true - true':  
          chtData = this.chartData.filter(f=> f.PHASE == phase && f.GROUP == group && f.SUBCONT == subcont)
          filterItem = 'SUBCONT'
          break
      }
      this.set_BarChart_Data(sChart, chtData, filterItem, axisData) // -----> below


      // call to draw line chart -----------------------------------------------------------------------------------
      // this.set_LineChart_Data(sChart, axisData) // -----> below
      this.canvasDropdown.moveToFront()
      
      this.draw_Tooltip(sChart, axisData)
    },


    set_BarChart_Data(selection, chtData, filterItem, axisData) {
      let groupArray = [ ...new Set(chtData.map(d => d[filterItem])) ]
      this.draw_Legends(chtData, groupArray, axisData) // -----> draw_Legends.js
      
      let chartArray = new Array(this.timeline.month_count)
      for(let i=0; i<this.timeline.month_count; i++) {
        chartArray[i] = []
        groupArray.forEach(d => {
          let cData = chtData.filter(f=> f[filterItem] == d)[i]
          chartArray[i].push({
            month: cData.month,
            P_WK:  cData.P_WK,
            A_WK:  cData.A_WK,
            D_WK:  cData.D_WK,
            P_CUM: cData.P_CUM,
            A_CUM: cData.A_CUM,
            D_CUM: cData.D_CUM,
          })
        })
      }
      this.draw_BarChart(selection, chartArray, groupArray, axisData) // -----> draw_Chart.js
    },


    set_LineChart_Data(selection, axisData) {
      let lineData = null
      this.chart.line.forEach((d,i)=> {
        if (d.type == 'ACT') {
          lineData = axisData.filter(f=> f.month <= this.timeline.cutoff.substr(0,7))
          // console.log(lineData)
        } else lineData = axisData
        this.draw_LineChart(selection, lineData, d) // -----> draw_Chart.js
      })
    },


  }
}