import * as d3 from 'd3'

export default {

  methods: {

    set_LocalValues(){

      this.safety = JSON.parse(JSON.stringify(this.DataItems))
      
      this.styles = JSON.parse(JSON.stringify(this.JQueries))
      
    },


  }
}