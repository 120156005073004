import * as d3 from 'd3'

export default {
  methods: {

    set_Axes(data_) {
      // Set primary & secoundary Axis
      let maxValue = d3.max([d3.max(data_, c => c.P_WK), d3.max(data_, c => c.A_WK),])
      this.primary.max = this.getMaxBound_v2(maxValue)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      maxValue = d3.max([d3.max(data_, c => c.P_CUM), d3.max(data_, c => c.A_CUM)])
      this.secoundary.max = this.getMaxBound_v2(maxValue)
      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
    },


    draw_Grid(selection) {

      let gridHeight = this.chart.height / 5
      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', gridHeight * i).attr('y2', gridHeight * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (gridHeight * i))  
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(
          (this.primary.max * (i*.2) < 10000) ? 
          (this.primary.max * (i*.2)).toFixed(0) : 
          ((this.primary.max * (i*.2))/1000).toFixed(1)+'k'
        )

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (gridHeight * i))
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(
          (this.secoundary.max * (i*.2) < 10000) ? 
          (this.secoundary.max * (i*.2)).toFixed(0) : 
          ((this.secoundary.max * (i*.2)/1000)).toFixed(1)+'k'
        )
      }
    },


    draw_BarChart(selection, data, groupArray, axisData) {
      let idx = this.timeline.month.findIndex(f=> f.code == this.timeline.cutoff.substr(0,7))
      let barWidth = 8
      
      data.forEach((arr,a)=> {
        let planAct = selection
        .append('g')
        .attr('id', `PLAN_${arr[0].month}`)
        .attr('transform', `translate(0.5,0)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', ()=> { 
          d3.select(`#PLAN_${arr[0].month}`).attr('opacity', .3)
          d3.select(`#PLAN_${arr[0].month}`).transition().duration(100).attr('opacity', .5)
          d3.select(`#TIME_${arr[0].month}`).transition().duration(100).attr('opacity', 1)

          this.change_Status(arr, axisData) // -----> draw_Legends.js
        })
        .on('mouseout', ()=> { 
          d3.select(`#PLAN_${arr[0].month}`).attr('opacity', .8)
          d3.select(`#PLAN_${arr[0].month}`).transition().duration(100).attr('opacity', 1)
          d3.select(`#TIME_${arr[0].month}`).transition().duration(100)
          .attr('opacity', (arr[0].month == this.timeline.cutoff.substr(0,7)) ? 1 : 0)

          this.change_Status(data[idx], axisData) // -----> draw_Legends.js
        })
        .on('click', () => {})

        let pY = this.chart.height, aY = this.chart.height
        arr.forEach((d,i) => {
          let mx_ = this.timeline.scale(d.month) + (this.timeline.monthWidth/2)

          planAct // PLAN
          .append('rect')
          .attr('x', mx_ - barWidth-.5)
          .attr('y', pY-this.primary.axis(d.P_WK))
          .attr('width', barWidth).attr('height', this.primary.axis(d.P_WK))
          .attr('fill', this.chart.colors[i])
          .attr('opacity', .5)
          pY = pY - this.primary.axis(d.P_WK)

          planAct // Actual
          .append('rect')
          .attr('x', mx_-.5)
          .attr('y', aY-this.primary.axis(d.A_WK))
          .attr('width', barWidth).attr('height', this.primary.axis(d.A_WK))
          .attr('fill', this.chart.colors[i])
          .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)
          aY = aY - this.primary.axis(d.A_WK)
        })

      })
    },


    draw_LineChart(selection, lineData, line) {

      let dev = null
      let lastOne = lineData.find(f=> f.month == this.timeline.cutoff.substr(0,7))
      if(lastOne) {
        dev = lastOne[this.chart.line[1].code]-lastOne[this.chart.line[0].code]
      }

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(d.month) + (this.timeline.monthWidth/2))
      .y(d => this.secoundary.axis(d[line.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(lineData))
      .attr('stroke', (line.type == 'ACT' && dev < 0) ? '#F35E90': line.sColor)
      .attr('stroke-dasharray', (line.type == 'PLAN') ? (3,3) : (0,0))
      .attr('stroke-width', line.sWidth).attr('fill', 'none')

      // draw the final cumulative value
      // if(line.type == 'ACT') {
      //   if(!lastOne) return
      //   lineChart
      //   .append('text')
      //   .attr('x', this.timeline.scale(lastOne.month) + (this.timeline.monthWidth/2))
      //   .attr('y', this.secoundary.axis(lastOne[line.code]))
      //   .attr('font-size', 11)
      //   .attr('fill', (dev < 0) ? '#F35E90' : '#44A9DF')
      //   .attr('alignment-baseline', 'middle')
      //   .text(
      //     (lastOne[line.code] < 10000) ? 
      //     lastOne[line.code].toFixed(0) : 
      //     (lastOne[line.code]/1000).toFixed(0) + 'k'
      //   )
      // }
    },
  }
}