import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      let sChart = this.canvasChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.set_Axes(this.chartData) // -----> draw_Chart.js
      this.draw_Grid(sChart) // -----> draw_Chart.js


      // Call Chart
      this.chart.bar.forEach(d=> {
        this.draw_BarChart(sChart, this.chartData, d)
      })


      // let chtData = null
      // this.chart.line.forEach((d,i)=> {
      //   if (d.type == 'ACT') chtData = this.chartData.filter(f=> f.CDATE <= this.timeline.cutoff)
      //   else chtData = this.chartData
      //   this.draw_LineChart(sChart, chtData, d)
      // })

      
      let legendData = this.chart.bar//.concat(this.chart.line)
      this.draw_Legends(legendData)
      this.draw_Tooltip(sChart)
    },


   


    



  }
}
           