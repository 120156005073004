import CONST from '../../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({

    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    tableData:      [],
    trendData:      [],
    chartData:      [],

    canvasForms:  null,
    canvasTrend:  null,
    canvasChart:  null,
    
    //Summary Table
    table: {
      column: [
        {title: 'AREA',        group: 'Y', code: 'AREA',   width: 70,   align: 'middle', link: 'N'},
        {title: 'Zone',        group: 'Y', code: 'ZONE',   width: 80,   align: 'middle', link: 'N'},
        {title: 'Yard',        group: '',  code: 'YARD',   width: 60,   align: 'middle', link: 'N'},
        {title: 'W.O',         group: '',  code: 'TOTAL',  width: 50,   align: 'end',    link: 'Y'},
        {title: 'Completed',   group: '',  code: 'F_ACT',  width: 50,   align: 'end',    link: 'Y'},
        {title: 'Remain',      group: '',  code: 'F_REM',  width: 50,   align: 'end',    link: 'Y'},
        {title: 'Progress',    group: '',  code: 'F_PRO',  width: 125,  align: 'end',    link: 'N'},
        {title: 'Completed',   group: '',  code: 'I_ACT',  width: 50,   align: 'end',    link: 'Y'},
        {title: 'Remain',      group: '',  code: 'I_REM',  width: 50,   align: 'end',    link: 'Y'},
        {title: 'Progress',    group: '',  code: 'I_PRO',  width: 125,  align: 'end',    link: 'N'},
      ],
      width: null,      
      lineHeight: 20,
      padding: 5    
    },


    // Histogram Chart
    timeline:     {
      cutoff   : null,
      startDate: null,
      endDate  : null,
      weekEnd  : 'friday',
      day      : [],
      week     : [],
      month    : [],
      year     : [],
      scale    : null,
    },
    
    chart: {
      x: 70,
      y: 530,
      width: 1250,
      height: 320,
      style: [
        {name:'Fab. wk.',      code: 'FAB_WK',  type:'BAR',  sWidth: 1, sColor: '#bcbcbc', opacity: 1, },
        {name:'Install wk.',   code: 'INS_WK',  type:'BAR',  sWidth: 1, sColor: '#83D2F5', opacity: 1, },
        {name:'Fab. Cuml.',    code: 'FAB_CUM', type:'LINE', sWidth: 2, sColor: '#bcbcbc', opacity: 1, },
        {name:'Install Cuml.', code: 'INS_CUM', type:'LINE', sWidth: 2, sColor: '#83D2F5', opacity: 1, },
      ],
    },

    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},


    // Trend Chart
    trend: {
      width:    550,
      height:   150,
      scaleX:   null,
      scaleY:   null,
      maxValue: 0,
      style: [
        {area: 'HULL',    color: '#E3A9BD', },
        {area: 'TOPSIDE', color: '#6AAECC', },
        {area: 'LQ',      color: '#E5D15E', },
      ]
    }

  }),






  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}