import * as d3 from 'd3'

export default {
  methods: {

    draw_barChart() {

      // Draw Bar Chart 
      this.timeline.xItems.forEach((d,i)=> {

        let stackY = this.chart.height
        let sum = 0

        this.chart.styles.filter(f=> f.value != 'LINE').forEach((st,s)=> {
          
          let value = this.chartData.find(f=> f[this.chart.code.item] == d.name && f[this.chart.code.bar] == st.value)[this.chart.code.value]
          if(value == undefined || value < 0) value = 0

          stackY = stackY - this.primary.axis(value)

          let bar = this.canvasChart
          .append('g')
          .attr('id', `BAR_${i}_${s}_${this.localId}`)
          .attr('opacity', 1)
          .call(this.call_LINK, st.filter, d, st)

          bar
          .append('rect')
          .attr('x', d.mx - (this.chart.barSize/2))
          .attr('y', stackY)
          .attr('width', this.chart.barSize).attr('height', this.primary.axis(value))
          .attr('fill', st.bColor).attr('opacity', st.opacity)
          
          if(st.vSize > 0 && value > 0) {
            bar
            .append('text')
            .attr('x', d.mx)
            .attr('y', stackY + (this.primary.axis(value)/2) +1.5)
            .attr('font-size', st.vSize).attr('fill',  st.vColor)
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(value)
          }
          sum = sum + value
        })

        // Period Total
        let stack = this.chart.stackTotal
        if(stack.visible == 'Y') {
          this.canvasChart
          .append('text')
          .attr('x', d.mx)
          .attr('y', stackY - 2)
          .attr('font-size', stack.tSize).attr('fill',  stack.tColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'baseline ')
          .text(sum)
        }

      })

    },


    draw_LineChart() {
      let _data = this.chartData.filter(f=> f[this.chart.code.bar] == 'LINE')
      let _st = this.chart.styles.find(f=> f.value == 'LINE')

      let lineFunc = d3.line()
      .x(d => this.timeline.xItems.find(f=> f.name == d[this.chart.code.item]).mx)
      .y(d => this.secondary.axis(d[this.chart.code.value]))

      this.canvasChart
      .append('path')
      .attr('d', lineFunc(_data))
      .attr('stroke', _st.bColor).attr('stroke-width', _st.size)
      .style("stroke-dasharray", (_st.ltype, _st.ltype))
      .attr('fill', 'none').attr('opacity', _st.opacity)

      _data.forEach((d,i)=> {
        // Node
        let x_ = this.timeline.xItems.find(f=> f.name == d[this.chart.code.item]).mx
        if(_st.node > 0) {
          this.canvasChart
          .append('circle')
          .attr('cx', x_)
          .attr('cy', this.secondary.axis(d[this.chart.code.value]))
          .attr('r', _st.node)
          .attr('fill', _st.bColor).attr('opacity', _st.opacity)
        }
        // Value
        if(_st.vSize > 0) {
          this.canvasChart
          .append('text')
          .attr('x', x_)
          .attr('y', this.secondary.axis(d[this.chart.code.value]) -4)
          .attr('font-size', _st.vSize).attr('fill',  _st.vColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'bottom')
          .text(d[this.chart.code.value])
        }
      })
    },
    
  }
}