import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      // console.log(this.styles)
      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5) scale(${this.styles.scale})`)
      .style('font-family', 'roboto')

      this.loadSvg(
        this.canvasForms, {
        x: 0, y:0, width: 850,
        url : `${this.HOST}/Dashboard/Safety/Injury.svg`
      }).then((svg) => {

        d3.selectAll("text").style('font-family', 'roboto')

        this.safety.filter(f=> f.GROUP == 'PYRAMID').forEach((d,i)=> {
          d3.select(`#PYRAMID-MONTH-${i+1}`).select('text')
          .attr('text-anchor', 'middle').text(d.MONTH)
          d3.select(`#PYRAMID-TOTAL-${i+1}`).select('text')
          .attr('text-anchor', 'middle').text(d.TOTAL)
        })

        this.safety.filter(f=> f.GROUP == 'BODY').forEach((d,i)=> {
          d3.select(`#BODY-MONTH-${i+1}`).select('text')
          .attr('text-anchor', 'middle').text(d.MONTH)
          d3.select(`#BODY-TOTAL-${i+1}`).select('text')
          .attr('text-anchor', 'middle').text(d.TOTAL)
        })

        d3.select(`#TITLE`).style('font-size', this.styles.tSize).text(this.styles.title)
      })
      
    },

  }
}
